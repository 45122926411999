import { ModuleAccess } from 'models/user'

export const constants = {
  REGEX: {
    EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
    TAGS: /<[^>]*>/g,
  },
  LOCAL_STORAGE: {
    AMEGO_WEBSITE_ID: (firebaseUid?: string) => `x-amego-website-id-${firebaseUid}`,
    ADMIN_AMEGO_WEBSITE_ID: 'x-admin-amego-website-id',
    ADMIN_RETURN_URL: 'x-admin-amego-return-url',
  },
  MODULE_ACCESS: {
    [ModuleAccess.Account]: 'Account',
    [ModuleAccess.Analytics]: 'Analytics',
    [ModuleAccess.Content]: 'Content',
    [ModuleAccess.Design]: 'Design',
    [ModuleAccess.Settings]: 'Settings',
    [ModuleAccess.Subscribers]: 'Attendees',
    [ModuleAccess.Community]: 'Community',
    [ModuleAccess.Messaging]: 'Engagements',
    [ModuleAccess.Quest]: 'Quest',
  },
}
